$IS_SE_SITE: false; $IS_NO_SITE: true;
@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';

:global(#app) {
  & > .container {
    height: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $pageGutter;
  text-align: center;
}

.icon {
  width: 40px;
  height: 40px;
}

.header {
  margin: 24px 0 16px;
  font-size: 24px;
  font-weight: normal;
}

.text {
  max-width: 320px;
  margin: 0;
  line-height: 1.5;
}
