.bet-body {
  $topMin: $menu-height + $top-info-height;
  $topMid: $menu-height-medium + $top-info-height;
  $topMax: $menu-height-big + $top-info-height-big;

  @extend %overflow-both;
  position: absolute;
  top: $topMin;
  right: 0;
  bottom: 0;
  left: 0;

  > .middle {
    position: relative;
  }

  &--sport {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint($breakMedium) {
    top: $topMid;
  }

  @include breakpoint($breakLarge) {
    top: $topMax;
  }
}
