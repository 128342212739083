.sprite {
  @include sprite;
}

.sprite.button-check {
  @include button-check;
}

.sprite.button-cup {
  @include button-cup;
}

.sprite.button-external {
  @include button-external;
}

.sprite.button-first-big {
  @include button-first-big;
}

.sprite.button-first {
  @include button-first;
}

.sprite.button-last-big {
  @include button-last-big;
}

.sprite.button-last {
  @include button-last;
}

.sprite.button-minus-big {
  @include button-minus-big;
}

.sprite.button-minus-small {
  @include button-minus-small;
}

.sprite.button-minus {
  @include button-minus;
}

.sprite.button-numpad-plus {
  @include button-numpad-plus;
}

.sprite.button-numpad {
  @include button-numpad;
}

.sprite.button-plus-big {
  @include button-plus-big;
}

.sprite.button-plus-small {
  @include button-plus-small;
}

.sprite.button-plus {
  @include button-plus;
}

.sprite.button-trash-small {
  @include button-trash-small;
}

.sprite.dialog-cancel {
  @include dialog-cancel;
}

.sprite.dialog-confirm {
  @include dialog-confirm;
}

.sprite.dropdown {
  @include dropdown;
}

.sprite.item-add {
  @include item-add;
}

.sprite.item-delete {
  @include item-delete;
}

.sprite.item-group-expand {
  @include item-group-expand;
}

.sprite.more-black-big {
  @include more-black-big;
}

.sprite.more-black {
  @include more-black;
}

.sprite.more-white-big {
  @include more-white-big;
}

.sprite.more-white {
  @include more-white;
}

.sprite.percent-sign {
  @include percent-sign;
}

.sprite.scratched-big {
  @include scratched-big;
}

.sprite.scratched {
  @include scratched;
}

.sprite.shopping-cart {
  @include shopping-cart;
}

.sprite.spinner {
  @include spinner;
}

.sprite.view-result-hidden {
  @include view-result-hidden;
}

.sprite.view-result {
  @include view-result;
}

.sprite.warning {
  @include warning;
}
