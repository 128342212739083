$bet-type-colors: (
  Stryktipset: $productColorStryktipset,
  Europatipset: $productColorEuropatipset,
  Topptipset: $productColorTopptipset,
  VM-Tipset: $productColorVM-Tipset,
  Powerplay: $productColorPowerplay,
  Bomben: $productColorBomben,
  V86: $productColorV86,
  V75: $productColorV75,
  GS75: $productColorGS75,
  V65: $productColorV65,
  V64: $productColorV64,
  V5: $productColorV5,
  V4: $productColorV4,
  V3: $productColorV3,
  T: $productColorT,
  DD: $productColorDD,
  LD: $productColorLD,
  FIVEPLUS: $productColorFivePlus,
);

@each $name, $value in $bet-type-colors {
  .bg-#{$name} {
    background-color: $value;
  }

  button.bg-#{$name} {
    transition: background-color 0.25s ease;
    &:active,
    &:hover:not(:disabled) {
      background-color: darken($value, 5%);
    }
  }
}
