//
// NORMALIZE
//
$base-font-family: 'Open Sans', sans-serif;
$base-font-size: 16px;

//
// WEIGHTS
//

$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

//
// COLORS
//

$background: #272727;
$bodyDefaultBackground: #f2f2f2;
$bodyAtgBackground: $background;
$blue: #09f;
$hover-opacity: 0.8;
$hover-white: #d4d4d4;
$hover-blue: #007acc;
$bodyText: #000;
$linkText: #094aa4;

$disabled-opacity: 0.5;
$disabled-blue: #004d80;

$productColorStryktipset: #00427a;
$productColorEuropatipset: #008733;
$productColorTopptipset: #f29400;
$productColorVM-Tipset: #00932b;
$productColorPowerplay: #a00813;
$productColorBomben: #790307;
$productColorV86: #802c7e;
$productColorV75: if($IS_SE_SITE, #014f9f, #008943);
$productColorGS75: #15614e;
$productColorV65: if($IS_SE_SITE, #c00a26, #b22a2d);
$productColorV64: if($IS_SE_SITE, #ed6c15, #c15701);
$productColorV5: if($IS_SE_SITE, #62ade0, #197eb0);
$productColorV4: if($IS_SE_SITE, #62ade0, #197eb0);
$productColorV3: if($IS_SE_SITE, #62ade0, #197eb0);
$productColorT: if($IS_SE_SITE, #62ade0, #197eb0);
$productColorDD: if($IS_SE_SITE, #62ade0, #197eb0);
$productColorLD: if($IS_SE_SITE, #62ade0, #197eb0);
$productColorFivePlus: #c24883;
$productSecondaryColorStryktipset: #002850;
$productSecondaryColorEuropatipset: #06521e;
$productSecondaryColorTopptipset: #8c4600;
$productSecondaryColorVM-Tipset: #006211;
$productSecondaryColorPowerplay: #570b04;
$productSecondaryColorBomben: #440f12;
$productBorderColorStryktipset: $productSecondaryColorStryktipset;
$productBorderColorEuropatipset: #095d23;
$productBorderColorTopptipset: $productSecondaryColorTopptipset;
$productBorderColorVM-Tipset: $productSecondaryColorVM-Tipset;
$productBorderColorPowerplay: $productSecondaryColorPowerplay;
$productBorderColorBomben: $productSecondaryColorBomben;

//
// BREAKPOINTS
//

// Min width 1367px
$breakXLarge: 1367px;

// Min width 1024px
$breakLarge: 1024px;

// Min width 768px
$breakMedium: 768px;

// Min width 480px
$breakSmall: 480px;

// Max width 479px – Target smaller devices only
$breakSmallDown: max-width 479px;

// Max width 767px – Target smaller devices only
$breakMobileDown: max-width 767px;

//
// PAGE (max widths)
//

$pageContentWidthWide: 1170px;
$pageContentWidthNarrow: 750px;
$pageContentWidthCompact: 580px;

//
// MARGINS
//

$pageGutter: 15px;

$pageContentStartMargin: 32px;
$pageContentStartMarginMedium: 48px;
$pageContentStartMarginLarge: 72px;

//
// MENU
//

$menu-height: 44px;
$menu-height-medium: 52px;
$menu-height-big: 78px;

$menu-buttons-height: 32px;
$menu-buttons-height-big: 64px;

//
// TOP INFO
//

$top-info-height: 44px;
$top-info-height-big: $top-info-height * 1.5;

//
// BOTTOM INFO
//

$bottom-info-background: #485a66;

$bottom-header-height: 46px;
$bottom-header-border-height: 5px;
$bottom-info-tab-height: $bottom-header-height -
  ($bottom-header-border-height * 2);

$bottom-header-height-big: 60px;
$bottom-header-border-height-big: 5px;
$bottom-info-tab-height-big: $bottom-header-height-big -
  ($bottom-header-border-height-big * 2);

//
// DIALOG
//

$dialog-padding: 12px;
$dialog-padding-breakMedium: 20px;
