$IS_SE_SITE: false; $IS_NO_SITE: true;
.root {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 430px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 16px;
  line-height: 1.5;
  text-align: center;
}

.img {
  max-width: 360px;
}

.h1 {
  margin: 32px 0 24px;
  font-size: 24px;
  font-weight: 300;
}

.p {
  margin: 0;
}
