$IS_SE_SITE: false; $IS_NO_SITE: true;
@import 'shared/styles/core/settings';
@import 'shared/styles/core/mixin';
@import 'breakpoint-sass/stylesheets/breakpoint';

.icon {
  display: block;
  width: 90px;
  margin: 50px auto;
}

.backButton {
  display: inline-block;
  margin-top: 24px;
  padding: 10px 16px;
  background-color: #fc6b1e;
  color: #fff;
  text-transform: uppercase;
  @extend %fontSemiBold;
}

@include breakpoint($breakLarge) {
  .backButton {
    padding: 16px 20px;
  }
}
